import { Routes, Route} from 'react-router-dom';
import Layout from './components/Layout';
import HomePage from './features/home/HomePage';
import ServicePublic from './features/services/ServicePublic';
// import ServiceDetails from './features/services/ServiceDetails';
import ServicePublicByCategory from './features/services/ServicePublicByCategory';
import ReservationService from './features/reservations/ReservationService';
import ReservationLocation from './features/reservations/ReservationLocation';
import ReservationPanier from './features/reservations/ReservationPanier';
import ReservationDate from './features/reservations/ReservationDate';
import ReservationClientInfo from './features/reservations/ReservationClientInfo';
import ReservationSummary from './features/reservations/ReservationSummary';
import ReservationConfirmation from './features/reservations/ReservationConfirmation';
import ReservationList from './features/reservations/ReservationList';
import ReservationListPending from './features/reservations/ReservationListPending';
import ReservationListPresent from './features/reservations/ReservationListPresent';
import ReservationListArchive from './features/reservations/ReservationListArchive';
import ReservationListFuture from './features/reservations/ReservationListFuture';
import ReservationListPast from './features/reservations/ReservationListPast';
import NewReservation from './features/reservations/NewReservation';
import GiftPublic from './features/gifts/GiftPublic';
import HelpPublic from './features/helps/HelpPublic';
// import About from './features/about/About';
import Login from './features/auth/Login';
import ForgetPassword from './features/auth/ForgetPassword';
import ResetPassword from './features/auth/ResetPassword';
import Register from './features/auth/Register';
import Activate from './features/auth/Activate';
import LayoutAdmin from './components/LayoutAdmin';
import Dashboard from './features/Dashboard';
import Profile from './features/auth/Profile';
import NoteList from './features/notes/NoteList';
import UserList from './features/users/UserList';
import ServiceList from './features/services/ServiceList';
import HelpList from './features/helps/HelpList';
import NewService from './features/services/NewService';
import EditService from './features/services/EditService';
import NewHelp from './features/helps/NewHelp';
import EditHelp from './features/helps/EditHelp';
import EditUser from './features/users/EditUser';
import NewUserForm from './features/users/NewUserForm';
import EditNote from './features/notes/EditNote';
import NewNote from './features/notes/NewNote';
import Prefetch from './features/auth/Prefetch';
import PersistLogin from './features/auth/PersistLogin';
import RequireAuth from './features/auth/RequireAuth';
import { ROLES } from './config/roles';
import PageNotFound from './features/PageNotFound';
import PostList from './features/posts/PostList';
import NewPost from './features/posts/NewPost';

import ProductPublic from './features/products/ProductPublic';
import ProductDetails from './features/products/ProductDetails';
import NewProduct from './features/products/NewProduct';
import EditProduct from './features/products/EditProduct';
import ProductList from './features/products/ProductList';
import ShoppingCart from './features/products/ShoppingCart';
// import ShippingAddress from './features/products/ShippingAddress';
// import PaymentMethod from './features/products/PaymentMethod';

import PlaceOrder from './features/orders/PlaceOrder';
import OrderDetails from './features/orders/OrderDetails';
import OrderList from './features/orders/OrderList';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        {/* <Route index={true} path='/' element={<ServicePublic />} /> */}
        <Route index={true} path='/' element={<HomePage />} />
        {/* <Route path='/service/:serviceId' element={<ServiceDetails />} /> */}
        <Route path='/reservation-service/:serviceId?' element={<ReservationService />} />
        <Route path='/reservation-location' element={<ReservationLocation />} />
        <Route path='/reservation-services' element={<ServicePublicByCategory />} />
        <Route path='/reservation-panier' element={<ReservationPanier />} />
        <Route path='/reservation-date' element={<ReservationDate />} />
        <Route path='/reservation-client-info' element={<ReservationClientInfo />} />

        <Route index={true} path='/products' element={<ProductPublic />} />
        <Route path='/product/:id' element={<ProductDetails />} />
        <Route index={true} path='/shoppingcart' element={<ShoppingCart />} />

        <Route path="/gifts" element={<GiftPublic />} />
        <Route path="/helps" element={<HelpPublic />} />
        {/* <Route path="/about" element={<About />} /> */}
        <Route path="/register" element={<Register />} />
        <Route path="/auth/activate/:token" element={<Activate />} />
        <Route path="/login" element={<Login />} />
        <Route path="/password-forget" element={<ForgetPassword />} />
        <Route path="/password-reset/:token" element={<ResetPassword />} />
      </Route>

        {/* Protected Routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={[...Object.values(ROLES)]} />}>
            <Route path="/" element={<Layout />}>
              <Route path='/reservation-summary' element={<ReservationSummary />} />
              <Route index={true} path='/placeorder' element={<PlaceOrder />} />
              <Route index={true} path='/order/:id' element={<OrderDetails />} />
            </Route>
            <Route element={<Prefetch />}>
              <Route path='dashboard' element={<LayoutAdmin />}>
                <Route index element={<Dashboard />} />
                <Route path='reservation-confirmation' element={<ReservationConfirmation />} />
                <Route path='profile' element={<Profile />} />
                <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
                  <Route path='users'>
                    <Route index element={<UserList />} />
                    <Route path=':id' element={<EditUser />} />
                    <Route path='new' element={<NewUserForm />} />
                  </Route>
                </Route>
                <Route element={<RequireAuth allowedRoles={[ROLES.Manager, ROLES.Admin]} />}>
                  <Route path='services'>
                    <Route index element={<ServiceList />} />
                    <Route path=':id' element={<EditService />} />
                    <Route path='new' element={<NewService />} />
                  </Route>
                </Route>
                <Route element={<RequireAuth allowedRoles={[ROLES.Manager, ROLES.Admin]} />}>
                  <Route path='products'>
                    <Route index element={<ProductList />} />
                    <Route path=':id' element={<EditProduct />} />
                    <Route path='new' element={<NewProduct />} />
                  </Route>
                </Route>
                <Route element={<RequireAuth allowedRoles={[ROLES.Manager, ROLES.Admin]} />}>
                  <Route path='orders'>
                    <Route index element={<OrderList />} />
                    {/* <Route path=':id' element={<EditService />} /> */}
                    {/* <Route path='new' element={<NewProduct />} /> */}
                  </Route>
                </Route>
                <Route element={<RequireAuth allowedRoles={[ROLES.Manager, ROLES.Admin]} />}>
                  <Route path='helps'>
                    <Route index element={<HelpList />} />
                    <Route path=':id' element={<EditHelp />} />
                    <Route path='new' element={<NewHelp />} />
                  </Route>
                </Route>
                <Route path='posts'>
                  <Route index element={<PostList />} />
                  {/* <Route path=':id' element={<EditNote />} /> */}
                  <Route path='new' element={<NewPost />} />
                </Route>
                <Route path='notes'>
                  <Route index element={<NoteList />} />
                  <Route path=':id' element={<EditNote />} />
                  <Route path='new' element={<NewNote />} />
                </Route>
                <Route element={<RequireAuth allowedRoles={[ROLES.Manager, ROLES.Admin]} />}>
                  <Route path='reservations'>
                    <Route index element={<ReservationList />} />
                    <Route path='page/:pageNumber' element={<ReservationList />} />
                    <Route path='new' element={<NewReservation />} />
                    <Route path='pending' element={<ReservationListPending />} />
                    <Route path='present' element={<ReservationListPresent />} />
                    <Route path='archive' element={<ReservationListArchive />} />
                  </Route>
                </Route>
                <Route element={<RequireAuth allowedRoles={[ROLES.Manager, ROLES.Admin, ROLES.Client]} />}>
                  <Route path='reservations'>
                    <Route index element={<ReservationList />} />
                    <Route path='future' element={<ReservationListFuture />} />
                    <Route path='past' element={<ReservationListPast />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path='*' element={<PageNotFound />} />
    </Routes>
  );
}

export default App;
