import React, { useState, useEffect } from 'react';

const PopupNotice = () => {
    const [showNotice, setShowNotice] = useState(false);
    const [hasPopupClosed, setHasPopupClosed] = useState(false);

    const currentDate = new Date();
    const noticeEndDate = new Date('2024-12-24');


    useEffect(() => {
        if (currentDate < noticeEndDate && !hasPopupClosed) {
            const openNoticeTimer = setTimeout(() => {
                setShowNotice(true);
            }, 1500);

            return () => clearTimeout(openNoticeTimer);
        }
    }, [currentDate, noticeEndDate, hasPopupClosed]);


    const closeNoticeButtonHandler = () => {
        setShowNotice(false);
        setHasPopupClosed(true);
    };

    const closeNoticeClickHandler = (e) => {
        if (e.target.className === 'modal-overlay') {
            setShowNotice(false);
        }
    };

    return (
        <>
            {showNotice && (
                <div className="modal modal-overlay" style={{ display: "block" }} onClick={closeNoticeClickHandler}>
                    <div 
                        className="modal-dialog modal-lg modal-dialog-centered modal-fullscreen-sm-down" 
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="modal-content">
                            <div className="modal-header bg-warning">
                                <h5 className="modal-title">
                                    <span className="text-uppercase text-center">Avis à nos clients</span> <br />Mise temporairement en pause les réservations pendant la période des fêtes
                                </h5>
                                <button 
                                    type="button" 
                                    className="btn-close" 
                                    onClick={closeNoticeButtonHandler} 
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body text-center">
                                <h4 className="pt-5 pb-3">Chers Clients d’Ongles Jessica,</h4>
                                <div className="fs-6 fw-bolder">
                                    <p>
                                        Nous vous informons qu’entre le 15 décembre et le 23 décembre, notre équipe vous accueillera avec plaisir <span className="text-uppercase fw-bold">sans rendez-vous</span> pour répondre à tous vos besoins beauté !
                                    </p>
                                    <p>
                                        Durant cette période, nous mettrons <span className="fw-bold">temporairement en pause les réservations</span> afin de rester disponibles pour chacun d’entre vous. Nous espérons que cette petite adaptation vous conviendra et vous remercions pour votre compréhension et votre fidélité.
                                        Nous avons hâte de vous retrouver au salon pour vous offrir un moment de détente et de soin avant les fêtes de fin d’année.
                                    </p>
                                </div>
                                <h5 className="py-4">
                                    À très bientôt et un grand merci pour votre compréhension !
                                </h5>
                            </div>
                            <div className="modal-footer bg-success-subtle">
                                <button type="button" className="btn btn-dark" onClick={closeNoticeButtonHandler}>Fermer</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PopupNotice;