import PlazaShowcaseOne from '../../../images/locations/plaza/plaza_01.jpg';
import PlazaShowcaseTwo from '../../../images/locations/plaza/plaza_02.jpg';
import PlazaShowcaseThree from '../../../images/locations/plaza/plaza_03.jpg';

function PlazaShowcase() {
    return (
        <div id="carouselPlazaShowcase" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img className="img-fluid rounded" src={PlazaShowcaseOne} alt='Plaza Laval'/>
                </div>
                <div className="carousel-item">
                    <img className="img-fluid rounded" src={PlazaShowcaseTwo} alt='Plaza Laval'/>
                </div>
                <div className="carousel-item">
                    <img className="img-fluid rounded" src={PlazaShowcaseThree} alt='Plaza Laval'/>
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselPlazaShowcase" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselPlazaShowcase" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
      )
  }
  
  export default PlazaShowcase