import { memo } from 'react';
import { useGetPublicServicesQuery } from './servicesApiSlice';

const ServicePublicSelect = ({ serviceId, manicure, pedicure, other }) => {
    const { service } = useGetPublicServicesQuery("publicServices", {
        selectFromResult: ({ data }) => ({
            service: data?.entities[serviceId]
        }),
    });

    if (service) {
        return (
            <>
                {
                    service.category === manicure && (
                        <>
                            <option
                                value={service._id}
                                required
                            >
                                {service.titre}
                            </option>
                            <hr />
                        </>
                    )
                }
                {
                    service.category === pedicure && (
                        <>
                            <option
                                value={service._id}
                                required
                            >
                                {service.titre}
                            </option>
                            <hr />
                        </>
                    )
                }
                {
                    service.category === other && (
                        <>
                            <option
                                value={service._id}
                                required
                            >
                                {service.titre}
                            </option>
                            <hr />
                        </>
                    )
                }
            </>
        )

    } else return null
}

const memoizedServicePublicSelect = memo(ServicePublicSelect)

export default memoizedServicePublicSelect