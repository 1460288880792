import React from 'react';
import { useNavigate } from "react-router-dom";
import PointOfService from '../../components/PointOfService';
import classes from './HomePage.module.css';
import heroImage from '../../images/panorama/hero-homepage.jpg';
import PopupNotice from '../../components/popups/notices/PopupNotice';

function HomePage() {
    const navigate = useNavigate();
    const handleReservationButton = () => {
        navigate('/reservation-location');
    };

    const handleCallButton = () => {
        window.location.href = 'tel:+15817419081'; // Directly set the tel link
    };

    return (
        <div className={classes.main}>
            <section className={classes.showcase} id={classes.panorama}>
                <div className={classes.overlay}>
                    <div className="placard-inner">
                        <div className="text-center">
                            <img className="img-fluid" src={heroImage} alt='Ongles Jessica'/>
                        </div>
                    </div>
                </div>
                <PopupNotice />
            </section>
            <section className={classes.features}>
                <div className="row p-5">
                    <div className="col-lg-4 my-3">
                        <i className="fa-solid fa-mobile"></i>
                        <h2 className='fw-normal'>Facilité de réservation</h2>
                        <p>
                            Nous comprenons l'importance de la commodité pour nos clients. C'est pourquoi nous proposons un processus de réservation facile et pratique, que ce soit en ligne, par téléphone ou en personne. Nous sommes là pour vous offrir des rendez-vous qui s'adaptent à votre temps.
                        </p>
                        {/* <Link to='/reservation-location' className="btn btn-outline-dark btn-lg">Commencez</Link> */}
                    </div>
                    <div className="col-lg-4 my-3">
                        <i className="fa-solid fa-wheelchair"></i>
                        <h2 className="fw-normal">Facilité d'accès</h2>
                        <p>
                            Nos salons sont situés dans des zones centrales, offrant un accès facile en voiture, en transports en commun et à pied. Nous nous efforçons de rendre nos emplacements visibles avec une signalisation claire et attrayante, ce qui permet aux clients de nous repérer facilement. 
                        </p>
                    </div>
                    <div className="col-lg-4 my-3">
                        <i className="fa-solid fa-hand-holding-heart"></i>
                        <h2 className="fw-normal">Service de qualité</h2>
                        <p>
                            Les membres de l'équipe doivent être compétents, bien formés et professionnels dans leur approche envers les clients. Cela inclut des compétences techniques, une connaissance approfondie des produits ou services offerts et une attitude amicale et serviable.
                        </p>
                    </div>
                </div>
            </section>
            {/* <hr /> */}
            <section>
                <div className="px-4 py-5 text-center">
                    <h2 className="display-6 fw-bold text-body-emphasis text-uppercase">Heures d'ouverture</h2>
                    <h3>Trouver nos emplacements</h3>
                    <div className="col-lg-6 mx-auto">
                        <p className="lead mb-4">
                            Cliquez sur un emplacement ci-dessus pour obtenir les heures d'ouverture, les nouvelles importantes, les renseignements sur les services, et plus encore.
                        </p>
                        <PointOfService />
                    </div>
                </div>
            </section>
            <section>
                <div className="px-4 py-5 text-center">
                    <h2 className="display-6 fw-bold text-body-emphasis text-uppercase">Réservation en ligne</h2>
                    <h3>Manucure, Pédicure, et Plus</h3>
                    <div className="col-lg-6 mx-auto">
                        <p className="lead mb-4">
                            Une carte de crédit n'est pas nécessaire pour effectuer votre réservation en ligne. Le paiement sera effectué sur place.
                        </p>
                        <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                            <button 
                                type="button" 
                                className="btn btn-outline-primary btn-lg px-4 gap-3 text-uppercase" 
                                onClick={handleReservationButton}
                            >
                                Réserver maintenant
                            </button>
                            {/* <Link to='/reservation-location' type="button" className="btn btn-outline-primary btn-lg px-4 gap-3 text-uppercase">
                                Réserver maintenant
                            </Link> */}
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="px-4 py-5 text-center">
                    <h2 className="display-6 fw-bold text-body-emphasis text-uppercase">Contactez-nous</h2>
                    <h3>Comment pouvons-nous vous aider?</h3>
                    <div className="col-lg-6 mx-auto">
                        <p className="lead mb-4">
                            Si vous souhaiter contacter Ongles Jessica, vous pouvez nous appeler au <br /> <span className='fw-bold'>(581) 741-9081</span> durant les heures d'ouverture et l'un de nos agents se fera un plaisir de vous aider.
                        </p>
                        <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                            <button
                                className="btn btn-outline-primary btn-lg px-4 gap-3 text-uppercase"
                                onClick={handleCallButton}
                            >
                                Appelle maintenant
                            </button>
                            {/* <a type="button" className="btn btn-outline-primary btn-lg px-4 gap-3 text-uppercase" href="tel:+1-581-741-9081">
                                Appelle maintenant
                            </a> */}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default HomePage