import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useUpdateColorNoteMutation } from "./reservationsApiSlice";
// import LoadingSpinner from "../../components/LoadingSpinner";
import { currentDate } from "../../utils/dateUtils";

function ReservationListPastItem({ reservation }) {
    const [updateColorNote, {
            isLoading,
            isSuccess,
            isError,
            error
    }] = useUpdateColorNoteMutation();

    const navigate = useNavigate()

    const [colorNote, setColorNote] = useState('');

    useEffect(() => {
        if (isSuccess) {
            navigate('/dashboard/reservations/past')
        }

    }, [isSuccess, navigate])

    const handleReservationColorNote = async () => {
        if (window.confirm('Voulez-vous ajuster cette couleur?')) {
            await updateColorNote({
                id: reservation._id,
                colorNote
            });
        }
    };

    // const reservedDate = new Date(reservation.reservationDate.dateReserved);
    // const limitDate = new Date(Date.now() - 86400000);
    // const limitDate = new Date(Date.now() - 43200000);

    return (
        <>
            {
                reservation?.reservationDate.dateReserved < currentDate && (
                    // <tr className={`${reservation.reservationLocation.locationReserved === '1' ? 'table-light' : ''}`}>
                    <tr className={`${reservation.status === "cancel" ? "text-decoration-line-through" : null}`}>
                        <td>{reservation.reservationDate.dateReserved}</td>
                        <td>{reservation.reservationDate.timeReserved}</td>
                        <td>{reservation.reservationClientInfo.client}</td>
                        <td>
                            {!reservation.reservationServices && reservation.reservationService && reservation.reservationService.titre}
                            {reservation.reservationServices?.map(service => `${service.titre} (${service.qty} place${service.qty > 1 ? 's' : ''})`).join(', ')}
                        </td>
                        {/* <td>{reservation.status}</td> */}
                        <td>
                            <button className="btn btn-outline-secondary btn-sm" type="button" data-bs-toggle="offcanvas" data-bs-target={`#offcanvasRight${reservation._id}`} aria-controls={`offcanvasRight${reservation._id}`}>
                                View
                            </button>
            
                            <div className="offcanvas offcanvas-end" tabIndex="-1" id={`offcanvasRight${reservation._id}`} aria-labelledby="offcanvasRightLabel">
                                <div className="offcanvas-header">
                                    <h5 className="offcanvas-title" id="offcanvasRightLabel">
                                        Détails de la Réservation
                                    </h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                </div>
                                <div className="offcanvas-body">
                                    <table className="table table-borderless">
                                        <tbody>
                                            <tr>
                                                <td className='col-sm-2 text-end text-muted'>Service</td>
                                                <td>
                                                    {!reservation.reservationServices && reservation.reservationService && reservation.reservationService.titre}
                                                    {reservation.reservationServices?.map(service => `${service.titre} (${service.qty} place${service.qty > 1 ? 's' : ''})`).join(', ')}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='col-sm-2 text-end text-muted'>Quand</td>
                                                <td>{reservation.reservationDate.dateReserved} à {reservation.reservationDate.timeReserved}</td>
                                            </tr>
                                            <tr>
                                                <td className='col-sm-2 text-end text-muted'>Client</td>
                                                <td>{reservation.reservationClientInfo.client}</td>
                                            </tr>
                                            <tr>
                                                <td className='col-sm-2 text-end text-muted'>Phone</td>
                                                <td>{reservation.reservationClientInfo.phone}</td>
                                            </tr>
                                            <tr>
                                                <td className='col-sm-2 text-end text-muted'>Lieu</td>
                                                <td>{reservation.reservationLocation?.locationReserved === "1" ? "La Pyramide (2360 chemin St-Foy, Québec, G1V 4H2)" : "Plaza Laval (2750 chemin St-Foy, Québec, G1V 1V6)"}</td>
                                            </tr>
                                            <tr>
                                                <td className='col-sm-2 text-end text-muted'>Comment</td>
                                                <td>{reservation.reservationClientInfo.comment}</td>
                                            </tr>
                                            <tr>
                                                <td className='col-sm-2 text-end text-muted'>Technicien</td>
                                                <td>{reservation.performer ? reservation.performer : '- - -'}</td>
                                            </tr>
                                        </tbody> 
                                    </table>
                                </div>
                            </div>
                        </td>
                        <td>
                            {
                                reservation.colorNote ? (
                                    reservation.colorNote
                                ) : (
                                    <form>
                                        <div className='input-group mb-3'>
                                            <input 
                                                className='form-control form-control-sm'
                                                name='colorNote'
                                                type='text'
                                                maxLength={20}
                                                placeholder='Entrez la couleur ici'
                                                aria-describedby='colorNote'
                                                value={colorNote}
                                                onChange={(e) => setColorNote(e.target.value)}
                                            />
                                            <button
                                                id='colorNote'
                                                className="btn btn-secondary btn-sm"
                                                onClick={handleReservationColorNote}
                                            >
                                                Enregistrer
                                            </button>
                                        </div>
                                    </form>
                                )
                            }
                        </td>
                    </tr>
                )
            }
        </>
    )
}

export default ReservationListPastItem;