import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAddNewServiceMutation } from "./servicesApiSlice";

const NewServiceForm = ({ users }) => {

    const [addNewService, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewServiceMutation()

    const navigate = useNavigate()

    const [category, setCategory] = useState('')
    const [titre, setTitre] = useState('')
    const [contenu, setContenu] = useState('')
    const [title, setTitle] = useState('')
    const [content, setContent] = useState('')
    const [titulo, setTitulo] = useState('')
    const [contenido, setContenido] = useState('')
    const [image, setImage] = useState('')
    const [video, setVideo] = useState('')
    const [durationType, setDurationType] = useState('')
    const [duration, setDuration] = useState('')
    const [durationMax, setDurationMax] = useState('')
    const [capacity, setCapacity] = useState('')
    const [priceType, setPriceType] = useState('')
    const [price, setPrice] = useState('')
    const [priceMax, setPriceMax] = useState('')
    const [priceDiscount, setPriceDiscount] = useState('')

    const [status, setStatus] = useState('private')
    const [userId, setUserId] = useState(users[0].id)

    useEffect(() => {
        if (isSuccess) {
            setTitre('')
            setContenu('')
            setTitle('')
            setContent('')
            setStatus('')
            setUserId('')
            navigate('/dashboard/services')
        }
    }, [isSuccess, navigate])

    // const onTitreChanged = e => setTitre(e.target.value)
    // const onContentChanged = e => setContent(e.target.value)
    // const onStatusChanged = e => setStatus(e.target.value)
    // const onUserIdChanged = e => setUserId(e.target.value)

    const canSave = [titre, contenu, status, userId, priceType].every(Boolean) && !isLoading

    const onSaveServiceClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            await addNewService({ 
                user: userId,
                category,
                titre,
                contenu,
                title,
                content,
                titulo,
                contenido,
                image,
                video,
                durationType,
                duration,
                durationMax,
                priceType,
                price,
                priceMax,
                priceDiscount,
                capacity,
                status 
            })
        }
    }

    const errClass = isError ? "errmsg" : "offscreen"
    // const validTitreClass = !titre ? "form__input--incomplete" : ''
    // const validContenuClass = !contenu ? "form__input--incomplete" : ''

    return (
        <>
            <main className="container">
                <p className={errClass}>{error?.data?.message}</p>
                <Link to={'/dashboard/helps'} className="text-decoration-none">
                    <i className="bi bi-caret-left-fill me-1"></i> Retour | Back
                </Link>
                <h2 className="pt-3">Add New Help</h2>
                <section className="py-3">
                    <form onSubmit={onSaveServiceClicked}>
                        <div className="mb-3">
                            <label htmlFor="category" className="form-label">Category (obligatoire | required)</label>
                            <select
                                id="category"
                                className="form-select"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                                required
                            >
                                <option value="">Please choose a category</option>
                                <hr />
                                <option value="manicure">Soins de Manucure</option>
                                <hr />
                                <option value="pedicure">Soins de Pédicure</option>
                                <hr />
                                <option value="other">Autre Services</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="titre">Titre en Francaise (obligatoire)</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="titre"
                                name="titre"
                                value={titre}
                                onChange={(e) => setTitre(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="contenu">Description en Francaise (obligatoire)</label>
                            <textarea 
                                name="contenu" 
                                id="contenu" 
                                className="form-control" 
                                value={contenu} 
                                onChange={(e) => setContenu(e.target.value)}
                            >
                            </textarea>
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="title">Title in English (optional)</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="title"
                                name="title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="content">Description in English (optional)</label>
                            <textarea 
                                name="content" 
                                id="content" 
                                className="form-control" 
                                value={content} 
                                onChange={(e) => setContent(e.target.value)}
                            >
                            </textarea>
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="titulo">Titulo en Espagnol (opcional)</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="titulo"
                                name="titulo"
                                value={titulo}
                                onChange={(e) => setTitulo(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="contenido">Descripcion en Espagnol (opcional)</label>
                            <textarea 
                                name="contenido" 
                                id="contenido" 
                                className="form-control" 
                                value={contenido} 
                                onChange={(e) => setContenido(e.target.value)}
                            >
                            </textarea>
                        </div>
                        <div className="row mb-3">
                            <div className="col">
                                <label className="form-label" htmlFor="image">Image (optional)</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="image"
                                    name="image"
                                    value={image}
                                    onChange={(e) => setImage(e.target.value)}
                                    disabled
                                />
                            </div>
                            <div className="col">
                                <label className="form-label" htmlFor="image">Video Link (optional)</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="video"
                                    name="video"
                                    value={video}
                                    onChange={(e) => setVideo(e.target.value)}
                                    disabled
                                />
                            </div>
                            <div className="col">
                                <label className="form-label" htmlFor="capacity">Capacity (obligatoire | required)</label>
                                <input 
                                    type="number" 
                                    className="form-control" 
                                    id="capacity"
                                    name="capacity"
                                    value={capacity}
                                    onChange={(e) => setCapacity(e.target.value)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="durationType" className="form-label">Duration Types (obligatoire | required)</label>
                                <select
                                    className="form-select"
                                    value={durationType}
                                    onChange={(e) => setDurationType(e.target.value)}
                                    required
                                >
                                    <option value="" selected>Please choose a duration type</option>
                                    <hr />
                                    <option value="fixed" >Fixed or Exact duration</option>
                                    <hr />
                                    <option value="range">Variable or Flexible duration</option>
                                </select>
                            </div>
                            {durationType && (
                                <div className="col">
                                    <label className="form-label" htmlFor="duration">
                                        {durationType === "fixed" ? "Duration Fixed" : "Duration Min"} (minutes | required)
                                    </label>
                                    <input 
                                        type="number" 
                                        className="form-control" 
                                        id="duration"
                                        name="duration"
                                        value={duration}
                                        onChange={(e) => setDuration(e.target.value)}
                                        required
                                    />
                                </div>
                            )}
                            {durationType === "range" && (
                                <div className="col">
                                    <label className="form-label" htmlFor="durationMax">Duration Max (minutes | required)</label>
                                    <input 
                                        type="number" 
                                        className="form-control" 
                                        id="durationMax"
                                        name="durationMax"
                                        value={durationMax}
                                        onChange={(e) => setDurationMax(e.target.value)}
                                        required
                                    />
                                </div>
                            )}
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="priceType" className="form-label">Price Types (obligatoire | required)</label>
                                <select
                                    className="form-select"
                                    value={priceType}
                                    onChange={(e) => setPriceType(e.target.value)}
                                    required
                                >
                                    <option value="" selected>Please choose a price type</option>
                                    <hr />
                                    <option value="fixed" >Fixed or Exact Price</option>
                                    <hr />
                                    <option value="range">Variable or Range Price</option>
                                    <hr />
                                    <option value="discount">Discount or Promotion Price</option>
                                </select>
                            </div>
                            {priceType && (
                                <div className="col">
                                    <label className="form-label" htmlFor="price">
                                        {priceType === "fixed" ? "Price Fixed" : priceType === "range" ? "Price Min" : "Price Regular"} (required)
                                    </label>
                                    <input 
                                        type="number" 
                                        className="form-control" 
                                        id="price"
                                        name="price"
                                        value={price}
                                        onChange={(e) => setPrice(e.target.value)}
                                        required
                                    />
                                </div>
                            )}
                            {priceType === "range" && (
                                <div className="col">
                                    <label className="form-label" htmlFor="priceMax">Price Max (optional)</label>
                                    <input 
                                        type="number" 
                                        className="form-control" 
                                        id="priceMax"
                                        name="priceMax"
                                        value={priceMax}
                                        onChange={(e) => setPriceMax(e.target.value)}
                                    />
                                </div>
                            )}
                            {priceType === "discount" && (
                                <div className="col">
                                    <label className="form-label" htmlFor="priceDiscount">Price Discount (required)</label>
                                    <input 
                                        type="number" 
                                        className="form-control" 
                                        id="priceDiscount"
                                        name="priceDiscount"
                                        value={priceDiscount}
                                        onChange={(e) => setPriceDiscount(e.target.value)}
                                        required
                                    />
                                </div>
                            )}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="status" className="form-label me-3">Status (Access Level)</label>
                            <div className="form-check form-check-inline">
                                <input 
                                    id="statusRadio1" 
                                    name="status" 
                                    className="form-check-input" 
                                    type="radio" 
                                    value="public"
                                    onChange={(e) => setStatus(e.target.value)}
                                />
                                <label htmlFor="statusRadio1" className="form-check-label">Public</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input 
                                    id="statusRadio2" 
                                    name="status"
                                    className="form-check-input" 
                                    type="radio" 
                                    value="private"
                                    onChange={(e) => setStatus(e.target.value)}
                                    checked
                                />
                                <label htmlFor="statusRadio2" className="form-check-label">Private</label>
                            </div>
                        </div>
                        <div className="mb-3">
                            <button
                                className="btn btn-primary"
                                type="submit"
                                title="Save"
                                disabled={!canSave}
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </section>
            </main>
        </>
    )
}

export default NewServiceForm