import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useUpdateServiceMutation, useDeleteServiceMutation } from "./servicesApiSlice";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const EditServiceForm = ({ service, users }) => {
    const { isManager, isAdmin } = useAuth()

    const [updateService, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateServiceMutation()

    const [deleteService, {
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delerror
    }] = useDeleteServiceMutation()

    const navigate = useNavigate()

    const [category, setCategory] = useState(service.category)
    const [titre, setTitre] = useState(service.titre)
    const [contenu, setContenu] = useState(service.contenu)
    const [title, setTitle] = useState(service.title)
    const [content, setContent] = useState(service.content)
    const [titulo, setTitulo] = useState(service.titulo)
    const [contenido, setContenido] = useState(service.contenido)
    const [image, setImage] = useState(service.image)
    const [video, setVideo] = useState(service.video)
    const [durationType, setDurationType] = useState(service.durationType)
    const [duration, setDuration] = useState(service.duration)
    const [durationMax, setDurationMax] = useState(service.durationMax)
    const [capacity, setCapacity] = useState(service.capacity)
    const [priceType, setPriceType] = useState(service.priceType)
    const [price, setPrice] = useState(service.price)
    const [priceMax, setPriceMax] = useState(service.priceMax)
    const [priceDiscount, setPriceDiscount] = useState(service.priceDiscount)
    const [status, setStatus] = useState(service.status)
    const [userId, setUserId] = useState(users[0].id)

    // const [question, setQuestion] = useState(help.question)
    // const [answer, setAnswer] = useState(help.answer)
    // const [status, setStatus] = useState(help.status)
    // const [userId, setUserId] = useState(users[0].id)

    useEffect(() => {
        if (isSuccess || isDelSuccess) {
            setTitre('')
            setContenu('')
            setTitle('')
            setContent('')
            setTitulo('')
            setContenido('')
            setImage('')
            setVideo('')
            setDurationType('')
            setDuration('')
            setDurationMax('')
            setCapacity('')
            setPriceType('')
            setPrice('')
            setPriceMax('')
            setPriceDiscount('')
            setStatus('')
            setUserId('')
            navigate('/dashboard/services')
        }

    }, [isSuccess, isDelSuccess, navigate])

    const canSave = [titre, contenu, durationType, duration, priceType, price, capacity, status, userId].every(Boolean) && !isLoading

    const onSaveServiceClicked = async (e) => {
        if (canSave) {
            await updateService({ 
                id: service.id,
                user: userId,
                category,
                titre,
                contenu,
                title,
                content,
                titulo,
                contenido,
                image,
                video,
                durationType,
                duration,
                durationMax,
                capacity,
                priceType,
                price,
                priceMax,
                priceDiscount,
                status
            })
        }
    }

    const onDeleteServiceClicked = async () => {
        if(window.confirm('Êtes-vous sûr(e)/ Are you sure?')) {
            await deleteService({ id: service.id })
        }
    }

    const errClass = (isError || isDelError) ? "errmsg" : "offscreen"
    // const validTitreClass = !titre ? "form__input--incomplete" : ''
    // const validContenuClass = !contenu ? "form__input--incomplete" : ''

    const errContent = (error?.data?.message || delerror?.data?.message) ?? ''

    let deleteButton = null
    if (isManager || isAdmin) {
        deleteButton = (
            <button
                className="btn btn-danger"
                title="Delete"
                onClick={onDeleteServiceClicked}
            >
                Delete Service
            </button>
        )
    }

    return (
        <>
            <main className="container py-3">
                <Link to={'/dashboard/services'} className="text-decoration-none">
                    <i className="bi bi-caret-left-fill me-1"></i> Retour | Back
                </Link>
                <p className={errClass}>{errContent}</p>
                <section className="text-center">
                    <div className="d-flex justify-content-between">
                        <div>
                            <h2>Edit Service</h2>
                        </div>
                        <div>
                            {deleteButton}
                        </div>
                    </div>
                </section>
                <section className="py-3">
                    <form>
                        <div className="mb-3">
                            <label htmlFor="category" className="form-label">Category (obligatoire | required)</label>
                            <select
                                id="category"
                                className="form-select"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                                required
                            >
                                <option value="">Please choose a category</option>
                                <hr />
                                <option value="manicure" selected = {category === 'fruit'}>Soins de Manucure</option>
                                <hr />
                                <option value="pedicure" selected = {category === 'diary'}>Soins de Pédicure</option>
                                <hr />
                                <option value="other" selected = {category === 'other'}>Autre Services</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="titre">Titre en Francaise (obligatoire)</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="titre"
                                name="titre"
                                value={titre}
                                onChange={(e) => setTitre(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="contenu">Description en Francaise (obligatoire)</label>
                            <textarea 
                                name="contenu" 
                                id="contenu" 
                                className="form-control" 
                                value={contenu} 
                                onChange={(e) => setContenu(e.target.value)}
                            >
                            </textarea>
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="title">Title in English (optional)</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="title"
                                name="title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="content">Description in English (optional)</label>
                            <textarea 
                                name="content" 
                                id="content" 
                                className="form-control" 
                                value={content} 
                                onChange={(e) => setContent(e.target.value)}
                            >
                            </textarea>
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="titulo">Titulo en Espagnol (opcional)</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="titulo"
                                name="titulo"
                                value={titulo}
                                onChange={(e) => setTitulo(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="contenido">Descripcion en Espagnol (opcional)</label>
                            <textarea 
                                name="contenido" 
                                id="contenido" 
                                className="form-control" 
                                value={contenido} 
                                onChange={(e) => setContenido(e.target.value)}
                            >
                            </textarea>
                        </div>
                        <div className="row mb-3">
                            <div className="col">
                                <label className="form-label" htmlFor="image">Image (optional)</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="image"
                                    name="image"
                                    value={image}
                                    onChange={(e) => setImage(e.target.value)}
                                    // disabled
                                />
                            </div>
                            <div className="col">
                                <label className="form-label" htmlFor="image">Video Link (optional)</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="video"
                                    name="video"
                                    value={video}
                                    onChange={(e) => setVideo(e.target.value)}
                                    disabled
                                />
                            </div>
                            <div className="col">
                                <label className="form-label" htmlFor="capacity">Capacity (obligatoire | required)</label>
                                <input 
                                    type="number" 
                                    className="form-control" 
                                    id="capacity"
                                    name="capacity"
                                    value={capacity}
                                    onChange={(e) => setCapacity(e.target.value)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="durationType" className="form-label">Duration Types (obligatoire | required)</label>
                                <select
                                    className="form-select"
                                    value={durationType}
                                    onChange={(e) => setDurationType(e.target.value)}
                                    required
                                >
                                    <option>Please choose a duration type</option>
                                    <hr />
                                    <option value="fixed" selected = {durationType === 'fixed'}>Fixed or Exact Duration</option>
                                    <hr />
                                    <option value="range" selected = {durationType === 'range'}>Variable or Flexible Duration</option>
                                </select>
                            </div>
                            {durationType && (
                                <div className="col">
                                    <label className="form-label" htmlFor="duration">
                                        {durationType === "fixed" ? "Duration Fixed" : "Duration Min"} (minutes | required)
                                    </label>
                                    <input 
                                        type="number" 
                                        className="form-control" 
                                        id="duration"
                                        name="duration"
                                        value={duration}
                                        onChange={(e) => setDuration(e.target.value)}
                                        required
                                    />
                                </div>
                            )}
                            {durationType === "range" && (
                                <div className="col">
                                    <label className="form-label" htmlFor="durationMax">Duration Max (minutes | required)</label>
                                    <input 
                                        type="number" 
                                        className="form-control" 
                                        id="durationMax"
                                        name="durationMax"
                                        value={durationMax}
                                        onChange={(e) => setDurationMax(e.target.value)}
                                        required
                                    />
                                </div>
                            )}
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="priceType" className="form-label">Price Types (obligatoire | required)</label>
                                <select
                                    className="form-select"
                                    value={priceType}
                                    onChange={(e) => setPriceType(e.target.value)}
                                    required
                                >
                                    <option>Please choose a price type</option>
                                    <hr />
                                    <option value="fixed" selected = {priceType === 'fixed'}>Fixed or Exact Price</option>
                                    <hr />
                                    <option value="range" selected = {priceType === 'range'}>Variable or Range Price</option>
                                    <hr />
                                    <option value="discount" selected = {priceType === 'discount'}>Discount or Promotion Price</option>
                                </select>
                            </div>
                            {priceType && (
                                <div className="col">
                                    <label className="form-label" htmlFor="price">
                                        {priceType === "fixed" ? "Price Fixed" : priceType === "range" ? "Price Min" : "Price Regular"} (required)
                                    </label>
                                    <input 
                                        type="number" 
                                        className="form-control" 
                                        id="price"
                                        name="price"
                                        value={price}
                                        onChange={(e) => setPrice(e.target.value)}
                                        required
                                    />
                                </div>
                            )}
                            {priceType === "range" && (
                                <div className="col">
                                <label className="form-label" htmlFor="priceMax">Price Max (optional)</label>
                                <input 
                                    type="number" 
                                    className="form-control" 
                                    id="priceMax"
                                    name="priceMax"
                                    value={priceMax}
                                    onChange={(e) => setPriceMax(e.target.value)}
                                />
                            </div>
                            )}
                            {priceType === "discount" && (
                                <div className="col">
                                <label className="form-label" htmlFor="priceDiscount">Price Discount (required)</label>
                                <input 
                                    type="number" 
                                    className="form-control" 
                                    id="priceDiscount"
                                    name="priceDiscount"
                                    value={priceDiscount}
                                    onChange={(e) => setPriceDiscount(e.target.value)}
                                    required
                                />
                            </div>
                            )}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="status" className="form-label me-3">Status (Access Level)</label>
                            <div className="form-check form-check-inline">
                                <input 
                                    id="statusRadio1" 
                                    name="status" 
                                    className="form-check-input" 
                                    type="radio" 
                                    value="public"
                                    onChange={(e) => setStatus(e.target.value)}
                                    checked = {status === 'public'}
                                />
                                <label htmlFor="statusRadio1" className="form-check-label">Public</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input 
                                    id="statusRadio2" 
                                    name="status"
                                    className="form-check-input" 
                                    type="radio" 
                                    value="private"
                                    onChange={(e) => setStatus(e.target.value)}
                                    checked = {status === 'private'}
                                />
                                <label htmlFor="statusRadio2" className="form-check-label">Private</label>
                            </div>
                        </div>
                        <div className="mb-3">
                            <button
                                className="btn btn-primary"
                                title="Save"
                                onClick={onSaveServiceClicked}
                                disabled={!canSave}
                            >
                                Update
                            </button>
                        </div>
                    </form>
                </section>
            </main>
        </>
    )
}

export default EditServiceForm